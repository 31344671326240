export const environment = {
    production: true,
    pbatID: 'PBAT973343',
    auth: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjIwMTMxNTYxfQ.7iTT5-7gnQVrabIb4af5wMRWWfPbWQfIZXBWvL8wA-A",
    title: "KENYA TRIPLE",
    
    roasterLatitude: "8.105416196996657", // == WETMILL LAT
    roasterLongitude: "36.98229328928205", // == WETMILL LONG
    wetmillLatitude: "-1.473787344913087", // == ROASTER LAT
    wetmillLongitude: "36.973491142775785"// == ROASTER LONG
  };